const route = ctx.root.$route
<template>
    <ion-header class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
            <ion-button color="primary-text" @click.prevent="goBack">
              <ion-icon slot="icon-only" :icon="goIcon" class="icons"></ion-icon>            
            </ion-button>
            <ion-button color="primary-text" @click.prevent="goHome" v-if="props.redirect!='home'">
              <ion-icon slot="icon-only" :icon="homeOutline" class="icons"></ion-icon>            
            </ion-button>
            
        </ion-buttons>
        <ion-buttons slot="end" class="ion-padding-end" v-if="state.showProfile">
            <router-link 
              class="ion-margin dark-text"
              :to="loginLink" 
              v-if="!userStore.isLoggedIn.value">Login
            </router-link>
            <ion-icon slot="icon-only" 
              :icon="personCircleOutline" 
              @click="openPopMenu"
              class="icons"/>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>  
</template>

<script>
import { IonHeader, IonToolbar, IonButtons, IonButton, IonIcon,
         popoverController } from '@ionic/vue'
import { defineComponent, onMounted, reactive } from 'vue'
import { arrowBack, personCircleOutline, homeOutline } from 'ionicons/icons'
import { useRouter, useRoute } from 'vue-router'
import userStore from '@/store/user'
import popMenuUSer from '@/components/popMenuUser.vue'

export default defineComponent({
  name: 'Toolbar',
  components: {
      IonHeader, IonToolbar, IonButtons, IonButton, IonIcon
  },
  props: {
    redirect: String,
    profile: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    const router = useRouter()
    const route = useRoute()
    const loginLink = `/login?redirect=${route.fullPath}`
    const goIcon = props.redirect === "home" ? homeOutline: arrowBack
    const state = reactive({
      showProfile: true
    })

    function goBack() {
      if ( props.redirect === "home" ) {
         router.push({name:"home"})
      } else
        router.go(-1)
    }

    function doLogin() {
      router.push({
        path: '/login',
        query: { redirect: route.fullPath }
      })

    }

    function goHome() {
      router.push({name:"home"})
    }

    onMounted(() => {
      state.showProfile = props.profile
    })

    async function openPopMenu(ev) {
      const popover = await popoverController
        .create({
          component: popMenuUSer,
          cssClass: 'popover',
          event: ev,
          translucent: true
        })
      return popover.present();
    }

    return { 
      arrowBack, personCircleOutline, 
      goBack , goIcon, props, state,
      userStore, openPopMenu, goHome,
      homeOutline, doLogin, loginLink
      }
  } 
})

</script>

<style scoped>
ion-toolbar {
  --background: #F2F8F7;
}
ion-toolbar ion-button {
    text-transform: initial;
    font-size: 17px;
   }

.icons {
  color: var(--ion-color-primary-text);
}
.popover {
  --box-shadow: none;
}

</style>