<template>
  <ion-page>
    <toolbar redirect="home" :profile="false"></toolbar>
    <ion-content>
      <ion-grid>
        <ion-row class="ion-justify-content-center">
          <ion-col size-md="6" size-sm="12">
            <div id="header">
                <img src="@/assets/img/sukisan-logo.png" />
            </div>
              <form>
                <div class="input-group">
                  <ion-item lines="none">
                    <ion-icon :icon="mailOutline" slot="start"  class="ion-margin-end"></ion-icon>
                    <ion-input 
                     type="email" 
                     placeholder="Email" 
                     formControlName="email"
                     v-model="state.email"></ion-input>
                  </ion-item>
                  <ion-item lines="none">
                    <ion-icon 
                      :icon="state.lockIcon" 
                      slot="start" 
                      class="ion-margin-end hover-link"
                      @click="state.clearText=!state.clearText"></ion-icon>
                    <ion-input 
                     :type="state.passwordType" 
                     placeholder="Password" 
                     formControlName="password"
                     v-model="state.password"></ion-input>
                  </ion-item>
                </div>
            
                <ion-button 
                  type="button"
                  expand="block" 
                  @click.prevent="loginViaEmail"
                  >Log in</ion-button>
                <ion-button 
                  type="button" 
                  expand="block" 
                  fill="clear"
                  @click.prevent="doRegister"
                  >Not yet a member? Sign up!
                </ion-button>
            
                <ion-button 
                  type="button" 
                  expand="block" 
                  class="fblogin-btn" 
                  @click.prevent="loginViaFB">
                  <ion-icon :icon="logoFacebook" slot="start"></ion-icon>
                  Sign in with Facebook
                </ion-button>
              </form>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import { IonPage, IonButton, IonInput, 
         IonContent, IonCol, IonGrid, IonRow, 
         IonItem, IonIcon } from '@ionic/vue'
import { defineComponent, reactive, inject, 
         computed } from 'vue'
import Toolbar from '@/components/Toolbar.vue'
import { logoFacebook, mailOutline, lockClosedOutline , lockOpenOutline} from 'ionicons/icons'
import userStore from '@/store/user'
import { useRouter, useRoute } from 'vue-router'
import { openToast } from '@/utils'
// import axios from '@/utils/api'

export default defineComponent({
  name: 'Login',
  components: { IonPage, IonButton, IonInput, 
         IonContent, Toolbar,
         IonItem,
         IonCol, IonGrid, IonRow,
         IonIcon
              },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const loadSpin = inject('loadSpinner')
    const state = reactive({
      email: process.env.VUE_APP_DEV_LOGIN || "",
      password:  process.env.VUE_APP_DEV_PWD || "",
      displayName: "",
      contactNo: "",
      clearText: false,
      passwordType: computed(() => {
         return state.clearText ? "text": "password"
      }),
      lockIcon: computed(() => {
         return state.clearText ? lockOpenOutline: lockClosedOutline
      })
    })
    async function setToken(data) {
      await userStore.setIdToken()
      // await userStore.setUserDetails(state.displayName, state.contactNo)
      // console.log(`stat: ${stat}`)
      console.log(route.query)
      if ('redirect' in route.query) {
        await router.push(route.query.redirect)
      } else {
        router.push('/')
      }
    }

    function loginViaEmail () {
      loadSpin.status = true
      firebase
        .auth()
        .signInWithEmailAndPassword(state.email, state.password)
        .then(data => {
          // console.log(data)
          setToken(data)
          // loadSpin.status = false
        })
        .catch(error => {
          // this.error = error;
          loadSpin.status = false
          // console.log(error.message)
          openToast(error.message, "", "Error", "middle", "danger" )
        });
    }

    function doRegister() {
      router.push('/register')
    }

    async function loadFacebookSDK(d, s, id) {
      // eslint-disable-next-line 
      var js,fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }
    // https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v9.0&appId=332490254835323&autoLogAppEvents=1" 
    // nonce="yg0qX3XB
    async function initFacebook() {
      window.fbAsyncInit = function() {
          window.FB.init({
            appId: "332490254835323", //You will need to change this
            cookie: true, // This is important, it's not enabled by default
            version: "v9.0"
          })
        }
    }

    async function loginViaFB () {
      const provider = new firebase.auth.FacebookAuthProvider()
      await loadFacebookSDK(document, "script", "facebook-jssdk")
      await initFacebook()
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(data => {
          setToken(data)
          loadSpin.status = false
      }).catch(error => {
        // Handle Errors here.
        // var errorCode = error.code;
        openToast(error.message, "", "Error", "middle", "danger" )
        // The email of the user's account used.
        // var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        // var credential = error.credential;
        // ...
      })
    }

    return { 
      state, 
      loginViaEmail, 
      loginViaFB, 
      logoFacebook, mailOutline, lockClosedOutline,
      openToast,
      userStore,
      doRegister
      }
  }
})

</script>


<style scoped>

 ion-content {
  --background: #f5f5f3;
  --color: var(--ion-color-primary-text-dark);
}
ion-content #header {
    display: flex;
    flex-direction: column;
    align-items: center;
}
/*
ion-content #header h1 {
    font-weight: 700;
    font-size: 26px;
    color: #000010;
}

ion-content #header img {
    width: 125px;
    height: 125px;
    border-radius: 50%;
    border: 8px solid #ffffff;
}

ion-content {
    --padding-top: 40%;
    --padding-start: 10%;
    --padding-end: 10%;
    __background: url('https://images.unsplash.com/photo-1536431311719-398b6704d4cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1234&q=80') 0 0/100% 100% no-repeat;
}
  */
.input-group {
    background: #fff;
    border-radius: 13px;
    overflow: hidden;
    margin-top: 10px;
    margin-bottom: 15px;
}
 
.errors {
    font-size: small;
    color: #fff;
    background: var(--ion-color-danger);
    padding-left: 15px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.fblogin-btn {
  --background: #4267B2;
}

.hover-link:hover {
  cursor: pointer;
}
ion-button {
  --border-radius: 13px;
  --box-shadow: none;
}
</style>
